<template>
    <div class="container mx-auto py-0">
        
        <swiper
            :slides-per-view="1"
            :space-between="50"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            :class="swiper" 
            :options="swiperOption"
        >
            <swiper-slide id="slider_1">
                <img class="hidden md:block" :src="Slider_Jubilaum_[$i18n.locale]" alt="Slider Jubiläum">
                <img class="md:hidden" :src="Slider_Jubilaum_Mobile_[$i18n.locale]" alt="Slider Jubiläum">
                <div class="absolute bottom-14 items-center left-0 right-0">
                    <button v-on:click="linkClick_de" v-if="$i18n.locale === 'de'" class="bg-sgbred hover:bg-sgbdarkred py-2 px-2 lg:w-300 w-60 text-white md:text-xl text-base font-bold border border-white hover:border-transparent rounded-full !important">{{ $t('header.sl-btn') }}</button>
                    <button v-on:click="linkClick_fr" v-if="$i18n.locale === 'fr'" class="bg-sgbred hover:bg-sgbdarkred py-2 px-2 lg:w-300 w-60 text-white md:text-xl text-base font-bold border border-white hover:border-transparent rounded-full !important">{{ $t('header.sl-btn') }}</button>
                    <button v-on:click="linkClick_it" v-if="$i18n.locale === 'it'" class="bg-sgbred hover:bg-sgbdarkred py-2 px-2 lg:w-300 w-60 text-white md:text-xl text-base font-bold border border-white hover:border-transparent rounded-full !important">{{ $t('header.sl-btn') }}</button>
                    
                </div>
            </swiper-slide>
        </swiper>
        
        <div id="jubiläum" class="section anchor xl:px-40 xl:py-20 py-10 px-5 text-left">
            <h1 class="xl:text-5xl text-4xl font-bold text-sgbred leading-tight">
                {{ $t("home.title-1") }}<br>
                {{ $t("home.title-1-1") }}
            </h1>
            <p class="xl:text-2xl text-xl font-bold text-black pt-9">
                {{ $t("home.text-1a") }}
            </p>
            <p class="xl:text-2xl text-xl text-black pt-9">
                {{ $t("home.text-1b") }}
            </p>
            <p class="xl:text-2xl text-xl text-black pt-5">
                {{ $t("home.text-1c") }}
            </p>
            <p class="xl:text-2xl text-xl text-black pt-5">
                <ul class="list-disc list-outside ml-5">
                    <li class=" font-semibold">{{ $t("home.text-list-1a") }}</li>
                    <li class="list-none mb-5">{{ $t("home.text-list-1b") }}</li>
                    <li class=" font-semibold">{{ $t("home.text-list-2a") }}</li>
                    <li class="list-none mb-5">{{ $t("home.text-list-2b") }}</li>
                    <li class=" font-semibold">{{ $t("home.text-list-3a") }}</li>
                    <li class="list-none mb-5">{{ $t("home.text-list-3b") }}</li>
                    <li class=" font-semibold">{{ $t("home.text-list-4a") }}</li>
                    <li class="list-none">{{ $t("home.text-list-4b") }}</li>
                </ul>
            </p>
            <p class="xl:text-2xl text-xl font-bold text-black pt-9">
                <a class="underline" :href="pdfLink" download="download">{{ $t("home.text-1d") }}</a>
            </p>
        </div>
        <div id="geschichte" class="section anchor xl:px-40 xl:py-20 py-10 px-5 text-left text-black bg-sgblightgrey">
            <h1 class="xl:text-5xl text-4xl font-bold text-sgbgold leading-tight">
                {{ $t("home.title-2") }}
            </h1>
            <p class="xl:text-2xl text-xl pt-5">
                {{ $t("home.text-2") }}
            </p>
        </div>
        <div class="section xl:px-40 xl:py-20 py-10 px-5 text-left text-white bg-sgbgrey">
            <swiper :navigation="true" :pagination='{"type": "fraction"}'  :loop="true" class="mySwiper">
                <swiper-slide>
                    <img src="../assets/img/slider/slide_1.png" alt="$t('home.history-1a')">
                    <div class="text mt-10 md:text-2xl text-xl">
                        <p>
                            <b>1941:</b> {{ $t("home.history-1") }}
                        </p>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <img src="../assets/img/slider/slide_2.png" alt="$t('home.history-2a')">
                    <div class="text mt-10 md:text-2xl text-xl">
                        <p>
                            <b>1945:</b> {{ $t("home.history-2") }}
                        </p>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <img src="../assets/img/slider/slide_3.png" alt="$t('home.history-3a')">
                    <div class="text mt-10 md:text-2xl text-xl">
                        <p>
                            <b>1946:</b> {{ $t("home.history-3") }}
                        </p>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <img src="../assets/img/slider/slide_4.jpg" alt="$t('home.history-4a')">
                    <div class="text mt-10 md:text-2xl text-xl">
                        <p>
                            <b>1948:</b> {{ $t("home.history-4") }}
                        </p>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <img src="../assets/img/slider/slide_5.png" alt="$t('home.history-5a')">
                    <div class="text mt-10 md:text-2xl text-xl">
                        <p>
                            <b>1979:</b> {{ $t("home.history-5") }}
                        </p>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <img src="../assets/img/slider/slide_6.png" alt="$t('home.history-6a')">
                    <div class="text mt-10 md:text-2xl text-xl">
                        <p>
                            <b>1988:</b> {{ $t("home.history-6") }}
                        </p>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <img src="../assets/img/slider/slide_7.png" alt="$t('home.history-7a')">
                    <div class="text mt-10 md:text-2xl text-xl">
                        <p>
                            <b>1988:</b> {{ $t("home.history-7") }}
                        </p>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <img src="../assets/img/slider/slide_8.jpg" alt="$t('home.history-8a')">
                    <div class="text mt-10 md:text-2xl text-xl">
                        <p>
                            <b>1991:</b> {{ $t("home.history-8") }}
                        </p>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <img src="../assets/img/slider/slide_9.png" alt="$t('home.history-9a')">
                    <div class="text mt-10 md:text-2xl text-xl">
                        <p>
                            <b>1991:</b> {{ $t("home.history-9") }}
                        </p>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <img src="../assets/img/slider/slide_10.jpg" alt="$t('home.history-10a')">
                    <div class="text mt-10 md:text-2xl text-xl">
                        <p>
                            <b>2009:</b> {{ $t("home.history-10") }}
                        </p>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <img src="../assets/img/slider/slide_11.png" alt="$t('home.history-11a')">
                    <div class="text mt-10 md:text-2xl text-xl">
                        <p>
                            <b>2015:</b> {{ $t("home.history-11") }}
                        </p>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <img src="../assets/img/slider/slide_12.jpg" alt="$t('home.history-12a')">
                    <div class="text mt-10 md:text-2xl text-xl">
                        <p>
                            <b>2016:</b> {{ $t("home.history-12") }}
                        </p>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <img src="../assets/img/slider/slide_13.jpg" alt="$t('home.history-13a')">
                    <div class="text mt-10 md:text-2xl text-xl">
                        <p>
                            <b>2019:</b> {{ $t("home.history-13") }}
                        </p>
                    </div>
                </swiper-slide>
            </swiper>

        </div>
        <a v-if="$i18n.locale === 'de'" href="https://www.sgb-fss.ch/product/jubilaeumsbuch-75-jahre-sgb-fss" target="_blank">
            <img class="hidden md:block" :src="Jubiläumsbuch_[$i18n.locale]">
            <img class="md:hidden w-full" :src="Jubiläumsbuch_Mobile_[$i18n.locale]">
        </a>
        <a v-if="$i18n.locale === 'fr'" href="https://www.sgb-fss.ch/fr/product/livre-du-jubile-75-ans-sgb-fss/" target="_blank">
            <img class="hidden md:block" :src="Jubiläumsbuch_[$i18n.locale]">
            <img class="md:hidden w-full" :src="Jubiläumsbuch_Mobile_[$i18n.locale]">
        </a>
        <a v-if="$i18n.locale === 'it'" href="https://www.sgb-fss.ch/it/product/libro-giubileo-per-i-75-anni-della-sgb-fss/" target="_blank">
            <img class="hidden md:block" :src="Jubiläumsbuch_[$i18n.locale]">
            <img class="md:hidden w-full" :src="Jubiläumsbuch_Mobile_[$i18n.locale]">
        </a>
        <div class="hidden md:block xl:px-40 py-10 px-5 bg-vgold">
            <div class="resp-container">
                <iframe class="resp-iframe" src="https://www.youtube.com/embed/iEI3Ae7KThU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
        <div class="md:hidden w-full xl:px-40 py-10 px-5 bg-vgold_mob">
            <div class="resp-container">
                <iframe class="resp-iframe" src="https://www.youtube.com/embed/iEI3Ae7KThU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>

        <div id="portraits" class="section xl:px-40 xl:py-20 py-10 px-5 text-left">
            <h1 class="xl:text-5xl text-4xl font-bold text-sgbred leading-tight">
                {{ $t("home.title-3") }}
            </h1>
            <p class="xl:text-2xl text-xl text-black pt-9">
                {{ $t("home.text-3a") }}
            </p>
        </div>
        <div class="flex flex-wrap mx-auto justify-center xl:px-40 md:flex-nowrap">
            <div v-on:click="openModal_1" class="hidden cursor-pointer md:block md:mr-2 mb-4 md:w-1/2 w-full">
                <img v-if="$i18n.locale === 'de'" class="w-full" src="../assets/img/cards/Felix_Urech_DE.png">
                <img v-if="$i18n.locale === 'fr'" class="w-full" src="../assets/img/cards/Felix_Urech_FR.png">
                <img v-if="$i18n.locale === 'it'" class="w-full" src="../assets/img/cards/Felix_Urech_IT.png">
            </div>
            <div v-on:click="openModal_1" class="md:hidden cursor-pointer mb-4 w-full">
                <img v-if="$i18n.locale === 'de'" class="w-full" src="../assets/img/cards/Felix_Urech_Mobile_DE.png">
                <img v-if="$i18n.locale === 'fr'" class="w-full" src="../assets/img/cards/Felix_Urech_Mobile_FR.png">
                <img v-if="$i18n.locale === 'it'" class="w-full" src="../assets/img/cards/Felix_Urech_Mobile_IT.png">
            </div>
            <Dialog class="w-full xl:w-1/2 p-3" v-model:visible="displayModal_1" :modal="true">
                <div class="resp-container">
                    <iframe v-if="$i18n.locale === 'de'" class="resp-iframe" src="https://www.youtube.com/embed/ia7dR6UxyvQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe v-if="$i18n.locale === 'fr'" class="resp-iframe" src="https://www.youtube.com/embed/qka90b87iOM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe v-if="$i18n.locale === 'it'" class="resp-iframe" src="https://www.youtube.com/embed/ySwGtZQWHrY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </Dialog>
             
            <div v-on:click="openModal_2" class="hidden cursor-pointer md:block md:ml-2 mb-4 md:w-1/2 w-full">
                <img v-if="$i18n.locale === 'de'" class="w-full" src="../assets/img/cards/Orazio_Lucioni_DE.png">
                <img v-if="$i18n.locale === 'fr'" class="w-full" src="../assets/img/cards/Orazio_Lucioni_FR.png">
                <img v-if="$i18n.locale === 'it'" class="w-full" src="../assets/img/cards/Orazio_Lucioni_IT.png">  
            </div>
            <div v-on:click="openModal_2" class="md:hidden cursor-pointer mb-4 w-full">
                <img v-if="$i18n.locale === 'de'" class="w-full" src="../assets/img/cards/Orazio_Lucioni_Mobile_DE.png">
                <img v-if="$i18n.locale === 'fr'" class="w-full" src="../assets/img/cards/Orazio_Lucioni_Mobile_FR.png">
                <img v-if="$i18n.locale === 'it'" class="w-full" src="../assets/img/cards/Orazio_Lucioni_Mobile_IT.png">  
            </div>
            <Dialog class="w-full xl:w-1/2 p-3" v-model:visible="displayModal_2" :modal="true">
                <div class="resp-container">
                    <iframe v-if="$i18n.locale === 'de'" class="resp-iframe" src="https://www.youtube.com/embed/wlr4uhjpptg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe v-if="$i18n.locale === 'fr'" class="resp-iframe" src="https://www.youtube.com/embed/0TAIs_-QS8U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe v-if="$i18n.locale === 'it'" class="resp-iframe" src="https://www.youtube.com/embed/W9nYt55Xr5M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </Dialog>
        </div>
        <div class="flex flex-wrap mx-auto justify-center xl:px-40 md:flex-nowrap">
            <div v-on:click="openModal_3" class="hidden cursor-pointer md:block md:mr-2 mb-4 md:w-1/2 w-full md:order-1 order-2">
                <img v-if="$i18n.locale === 'de'" class="w-full" src="../assets/img/cards/Roland_Hermann_DE.png">
                <img v-if="$i18n.locale === 'fr'" class="w-full" src="../assets/img/cards/Roland_Hermann_FR.png">
                <img v-if="$i18n.locale === 'it'" class="w-full" src="../assets/img/cards/Roland_Hermann_IT.png">
            </div>
            <div v-on:click="openModal_3" class="md:hidden cursor-pointer mb-4 w-full md:order-1 order-2">
                <img v-if="$i18n.locale === 'de'" class="w-full" src="../assets/img/cards/Roland_Herrmann_Mobile_DE.png">
                <img v-if="$i18n.locale === 'fr'" class="w-full" src="../assets/img/cards/Roland_Herrmann_Mobile_FR.png">
                <img v-if="$i18n.locale === 'it'" class="w-full" src="../assets/img/cards/Roland_Herrmann_Mobile_IT.png">
            </div>
            <Dialog class="w-full xl:w-1/2 p-3" v-model:visible="displayModal_3" :modal="true">
                <div class="resp-container">
                    <iframe v-if="$i18n.locale === 'de'" class="resp-iframe" src="https://www.youtube.com/embed/iSBKgL_Gtaw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe v-if="$i18n.locale === 'fr'" class="resp-iframe" src="https://www.youtube.com/embed/P4jM5SfD_3c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe v-if="$i18n.locale === 'it'" class="resp-iframe" src="https://www.youtube.com/embed/NOzL8gBZYOI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </Dialog>

            <div v-on:click="openModal_4" class="hidden cursor-pointer md:block md:ml-2 mb-4 md:w-1/2 w-full md:order-2 order-1">
                <img v-if="$i18n.locale === 'de'" class="w-full" src="../assets/img/cards/Beat_Kleeb_DE.png">
                <img v-if="$i18n.locale === 'fr'" class="w-full" src="../assets/img/cards/Beat_Kleeb_FR.png">
                <img v-if="$i18n.locale === 'it'" class="w-full" src="../assets/img/cards/Beat_Kleeb_IT.png">
            </div>
            <div v-on:click="openModal_4" class="md:hidden cursor-pointer mb-4 w-full md:order-2 order-1">
                <img v-if="$i18n.locale === 'de'" class="w-full" src="../assets/img/cards/Beat_Kleeb_Mobile_DE.png">
                <img v-if="$i18n.locale === 'fr'" class="w-full" src="../assets/img/cards/Beat_Kleeb_Mobile_FR.png">
                <img v-if="$i18n.locale === 'it'" class="w-full" src="../assets/img/cards/Beat_Kleeb_Mobile_IT.png">
            </div>
            <Dialog class="w-full xl:w-1/2 p-3" v-model:visible="displayModal_4" :modal="true">
                <div class="resp-container">
                    <iframe v-if="$i18n.locale === 'de'" class="resp-iframe" src="https://www.youtube.com/embed/uo28ye8X_qo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe v-if="$i18n.locale === 'fr'" class="resp-iframe" src="https://www.youtube.com/embed/_R2V8w5wYWI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe v-if="$i18n.locale === 'it'" class="resp-iframe" src="https://www.youtube.com/embed/C-XQgsg7nRE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </Dialog>
        </div> 
        <div class="flex flex-wrap mx-auto justify-center xl:px-40 md:flex-nowrap">
            <div v-on:click="openModal_5" class="hidden cursor-pointer md:block md:mr-2 mb-4 md:w-1/2 w-full">
                <img v-if="$i18n.locale === 'de'" class="w-full" src="../assets/img/cards/Donald_Shelton_DE.png">
                <img v-if="$i18n.locale === 'fr'" class="w-full" src="../assets/img/cards/Donald_Shelton_FR.png">
                <img v-if="$i18n.locale === 'it'" class="w-full" src="../assets/img/cards/Donald_Shelton_IT.png">     
            </div>
            <div v-on:click="openModal_5" class="md:hidden cursor-pointer mb-4 w-full">
                <img v-if="$i18n.locale === 'de'" class="w-full" src="../assets/img/cards/Donald_Shelton_Mobile_DE.png">
                <img v-if="$i18n.locale === 'fr'" class="w-full" src="../assets/img/cards/Donald_Shelton_Mobile_FR.png">
                <img v-if="$i18n.locale === 'it'" class="w-full" src="../assets/img/cards/Donald_Shelton_Mobile_IT.png">     
            </div>
            <Dialog class="w-full xl:w-1/2 p-3" v-model:visible="displayModal_5" :modal="true">
                <div class="resp-container">
                    <iframe v-if="$i18n.locale === 'de'" class="resp-iframe" src="https://www.youtube.com/embed/5yMf1Ju9xJ0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe v-if="$i18n.locale === 'fr'" class="resp-iframe" src="https://www.youtube.com/embed/IHMjL3MKmns" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe v-if="$i18n.locale === 'it'" class="resp-iframe" src="https://www.youtube.com/embed/ceVDZpQGOqQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </Dialog>

            <div v-on:click="openModal_6" class="hidden  cursor-pointer md:block md:ml-2 mb-4 md:w-1/2 w-full">
                <img v-if="$i18n.locale === 'de'" class="w-full" src="../assets/img/cards/Dr._Tatjanna_Binggeli_DE.png">
                <img v-if="$i18n.locale === 'fr'" class="w-full" src="../assets/img/cards/Dr._Tatjanna_Binggeli_FR.png">
                <img v-if="$i18n.locale === 'it'" class="w-full" src="../assets/img/cards/Dr._Tatjanna_Binggeli_IT.png">       
            </div>
            <div v-on:click="openModal_6" class="md:hidden  cursor-pointer mb-4 w-full">
                <img v-if="$i18n.locale === 'de'" class="w-full" src="../assets/img/cards/Dr._Tatjana_Binggeli_Mobile_DE.png">
                <img v-if="$i18n.locale === 'fr'" class="w-full" src="../assets/img/cards/Dr._Tatjana_Binggeli_Mobile_FR.png">
                <img v-if="$i18n.locale === 'it'" class="w-full" src="../assets/img/cards/Dr._Tatjana_Binggeli_Mobile_IT.png">       
            </div>
            <Dialog class="w-full xl:w-1/2 p-3" v-model:visible="displayModal_6" :modal="true">
                <div class="resp-container">
                    <iframe v-if="$i18n.locale === 'de'" class="resp-iframe" src="https://www.youtube.com/embed/zB-BuXpWTbk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe v-if="$i18n.locale === 'fr'" class="resp-iframe" src="https://www.youtube.com/embed/AELy0dh2ODU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe v-if="$i18n.locale === 'it'" class="resp-iframe" src="https://www.youtube.com/embed/nu6HAGDW5B8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </Dialog>
        </div>
        <div class="section xl:px-40 xl:py-10 py-5 px-5 text-left">
            <p class="xl:text-2xl text-xl text-black">
                {{ $t("home.text-3b") }}<br>
                <span>
                    <a class="underline" v-if="$i18n.locale === 'de'" href="https://www.sgb-fss.ch/product/jubilaeumsbuch-75-jahre-sgb-fss" target="blank">{{ $t("home.text-3link") }}</a>
                    <a class="underline" v-if="$i18n.locale === 'fr'" href="https://www.sgb-fss.ch/fr/product/livre-du-jubile-75-ans-sgb-fss/" target="blank">{{ $t("home.text-3link") }}</a>
                    <a class="underline" v-if="$i18n.locale === 'it'" href="https://www.sgb-fss.ch/it/product/libro-giubileo-per-i-75-anni-della-sgb-fss/" target="blank">{{ $t("home.text-3link") }}</a>
                    .
                </span>
            </p>
        </div>
        <div id="videos" class="section xl:mt-24 xl:px-40 xl:py-20 py-10 px-5 text-left text-black bg-sgblightgrey">
            <h1 class="xl:text-5xl text-4xl font-bold text-sgbgold leading-tight">
                {{ $t("home.title-4") }}
            </h1>
            <p class="xl:text-2xl text-xl py-10">
                {{ $t("home.text-4") }}
            </p>
            <Accordion>
                <AccordionTab :class="text-xl" :header="$t ('home.video-h1')">
                    <div class="resp-container">
                        <iframe v-if="$i18n.locale === 'de'" class="resp-iframe" src="https://www.youtube.com/embed/8lGJWqXXry4?list=PLTV4_ZQKWo3J9bQZRgeaUvWwY1VERlWEq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <iframe v-if="$i18n.locale === 'fr'" class="resp-iframe" src="https://www.youtube.com/embed/wqlE-XPaCKQ?list=PLTV4_ZQKWo3J9bQZRgeaUvWwY1VERlWEq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <iframe v-if="$i18n.locale === 'it'" class="resp-iframe" src="https://www.youtube.com/embed/Gpcc42-BXA8?list=PLTV4_ZQKWo3J9bQZRgeaUvWwY1VERlWEq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </AccordionTab>
                <AccordionTab :header="$t ('home.video-h2')">
                    <div class="resp-container">
                        <iframe class="resp-iframe" src="https://www.youtube.com/embed/lIJxEVOYu0o?list=PLTV4_ZQKWo3J9bQZRgeaUvWwY1VERlWEq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </AccordionTab>
                <AccordionTab :header="$t ('home.video-h3')">
                    <div class="resp-container">
                        <iframe v-if="$i18n.locale === 'de'" class="resp-iframe" src="https://www.youtube.com/embed/N6o-e_kuvXY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <iframe v-if="$i18n.locale === 'fr'" class="resp-iframe" src="https://www.youtube.com/embed/HjRT26q4zFU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <iframe v-if="$i18n.locale === 'it'" class="resp-iframe" src="https://www.youtube.com/embed/R59Wb53Hk6c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </AccordionTab>
                <AccordionTab :header="$t ('home.video-h4')">
                    <div class="resp-container">
                        <iframe v-if="$i18n.locale === 'de'" class="resp-iframe" src="https://www.youtube.com/embed/vW0Base2DdA?list=PLTV4_ZQKWo3J9bQZRgeaUvWwY1VERlWEq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <iframe v-if="$i18n.locale === 'fr'" class="resp-iframe" src="https://www.youtube.com/embed/mxpnDgxtBeE?list=PLTV4_ZQKWo3J9bQZRgeaUvWwY1VERlWEq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <iframe v-if="$i18n.locale === 'it'" class="resp-iframe" src="https://www.youtube.com/embed/hA2RfhEJOgw?list=PLTV4_ZQKWo3J9bQZRgeaUvWwY1VERlWEq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </AccordionTab>
                
                <AccordionTab :header="$t ('home.video-h5')">
                    <div class="resp-container mb-5">
                        <iframe class="resp-iframe" src="https://www.youtube.com/embed/i9jMhdoN5KM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <div class="resp-container mb-5">
                        <iframe class="resp-iframe" src="https://www.youtube.com/embed/bUUUZEvB_nw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <div class="resp-container mb-5">
                        <iframe class="resp-iframe" src="https://www.youtube.com/embed/eb5VTgd9Ihg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <div class="resp-container mb-5">    
                        <iframe class="resp-iframe" src="https://www.youtube.com/embed/Ct_JjPS-soI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <div class="resp-container mb-5">
                        <iframe class="resp-iframe" src="https://www.youtube.com/embed/gnNkEXCbsBE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <div class="resp-container">
                        <iframe class="resp-iframe" src="https://www.youtube.com/embed/ZXGrJFCFFsk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </AccordionTab>
                
                <AccordionTab :header="$t ('home.video-h6')">
                    <div class="resp-container">
                        <iframe v-if="$i18n.locale === 'de'" class="resp-iframe" src="https://www.youtube.com/embed/0UmyI72gj0Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <iframe v-if="$i18n.locale === 'fr'" class="resp-iframe" src="https://www.youtube.com/embed/M7SYSBB_3YQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <iframe v-if="$i18n.locale === 'it'" class="resp-iframe" src="https://www.youtube.com/embed/scYPhpZNlT8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </AccordionTab>
               
            </Accordion>
        </div>
    </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/swiper-bundle.css';

import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"
// import Swiper core and required modules
import SwiperCore, {
  Pagination,Navigation
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Pagination,Navigation]);

// Source Wechsel (Bild) je nach Sprache
import Slider_Jubilaum_de from '@/assets/img/Slider_Jubilaum_de.gif'
import Slider_Jubilaum_fr from '@/assets/img/Slider_Jubilaum_fr.gif'
import Slider_Jubilaum_it from '@/assets/img/Slider_Jubilaum_it.gif'
import Slider_Jubilaum_Mobile_de from '@/assets/img/Slider_Jubilaum_Mobile_de.gif'
import Slider_Jubilaum_Mobile_fr from '@/assets/img/Slider_Jubilaum_Mobile_fr.gif'
import Slider_Jubilaum_Mobile_it from '@/assets/img/Slider_Jubilaum_Mobile_it.gif'

import Jubiläumsbuch_de from '@/assets/img/Jubiläumsbuch_de.png'
import Jubiläumsbuch_fr from '@/assets/img/Jubiläumsbuch_fr.png'
import Jubiläumsbuch_it from '@/assets/img/Jubiläumsbuch_it.png'
import Jubiläumsbuch_Mobile_de from '@/assets/img/Jubiläumsbuch_Mobile_de.png'
import Jubiläumsbuch_Mobile_fr from '@/assets/img/Jubiläumsbuch_Mobile_fr.png'
import Jubiläumsbuch_Mobile_it from '@/assets/img/Jubiläumsbuch_Mobile_it.png'  


export default {
    name: 'Home',
    data() {
            return {

                displayModal_1: false,
                displayModal_2: false,
                displayModal_3: false,
                displayModal_4: false,
                displayModal_5: false,
                displayModal_6: false,
                position: 'center',

                Slider_Jubilaum_: {
                    de: Slider_Jubilaum_de,
                    fr: Slider_Jubilaum_fr,
                    it: Slider_Jubilaum_it
                },
                Slider_Jubilaum_Mobile_: {
                    de: Slider_Jubilaum_Mobile_de,
                    fr: Slider_Jubilaum_Mobile_fr,
                    it: Slider_Jubilaum_Mobile_it
                },

                Jubiläumsbuch_: {
                    de: Jubiläumsbuch_de,
                    fr: Jubiläumsbuch_fr,
                    it: Jubiläumsbuch_it
                },
                Jubiläumsbuch_Mobile_: {
                    de: Jubiläumsbuch_Mobile_de,
                    fr: Jubiläumsbuch_Mobile_fr,
                    it: Jubiläumsbuch_Mobile_it
                },

                pdfLink: require("@/assets/pdf/Programm_Jubilaum_06.2021.pdf"),
  
            }
            
        },
           
        components: { 
            Swiper,
            SwiperSlide
        },
        mounted() {
            // Aufruf Modals
            this.$nextTick(() => {
                if (window.location.search.indexOf('openDialog_1') > -1) {
                    this.openModal_1();
                }
                if (window.location.search.indexOf('openDialog_2') > -1) {
                    this.openModal_2();
                }
                if (window.location.search.indexOf('openDialog_3') > -1) {
                    this.openModal_3();
                }
                if (window.location.search.indexOf('openDialog_4') > -1) {
                    this.openModal_4();
                }
                if (window.location.search.indexOf('openDialog_5') > -1) {
                    this.openModal_5();
                }
                if (window.location.search.indexOf('openDialog_6') > -1) {
                    this.openModal_6();
                }
            });
        },

        methods: {
            linkClick_de: function() {
            window.open("https://www.sgb-fss.ch")
            },
            linkClick_fr: function() {
                window.open("https://www.sgb-fss.ch/fr/")
            },
            linkClick_it: function() {
                window.open("https://www.sgb-fss.ch/it/")
            },

            openModal_1() {
                this.displayModal_1 = true;
            },
            closeModal_1() {
                this.displayModal_1 = false;
            },
            openModal_2() {
                this.displayModal_2 = true;
            },
            closeModal_2() {
                this.displayModal_2 = false;
            },
            openModal_3() {
                this.displayModal_3 = true;
            },
            closeModal_3() {
                this.displayModal_3 = false;
            },
            openModal_4() {
                this.displayModal_4 = true;
            },
            closeModal_4() {
                this.displayModal_4 = false;
            },
            openModal_5() {
                this.displayModal_5 = true;
            },
            closeModal_5() {
                this.displayModal_5 = false;
            },
            openModal_6() {
                this.displayModal_6 = true;
            },
            closeModal_6() {
                this.displayModal_6 = false;
            },
 
        }

    
}
</script>

<style>
     /*-------- Slider -------- */

    .swiper-container {
        width: 100%;
        height: 100%;
    }

    .swiper-slide img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }     

    
    .swiper-button-prev, .swiper-container-rtl .swiper-button-next,
    .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
        color: white !important;
    }
    
    .swiper-pagination-fraction {
        bottom: 135px !important;
    }
    @media screen and (min-width: 667px) and (max-width: 740px) {
        .swiper-pagination-fraction {
            bottom: 155px !important;
        }
    }
    @media screen and (min-width: 741px) and (max-width: 812px) {
        .swiper-pagination-fraction {
            bottom: 200px !important;
        }
    }
    @media screen and (min-width: 401px) and (max-width: 600px) {
        .swiper-pagination-fraction {
            bottom: 225px !important;
        }
    }

    @media screen and (max-width: 400px) {
        .swiper-pagination-fraction {
            bottom: 250px !important;
        }
    }
    
    .swiper-button-next, .swiper-button-prev {
        top: 40% !important;
        
    }
    @media screen and (max-width: 640px) {
        .swiper-button-next, .swiper-button-prev {
            top: 22% !important;
            --swiper-navigation-size: 22px !important;
        }
    }
    

    /* --------- Accordion ----------- */
    .p-accordion-header {
        font-size: 25px;  
    }
    @media screen and (max-width: 640px) {
        .p-accordion-header {
            font-size: 18px;  
        }
    }
    
    .p-accordion .p-accordion-tab {
        box-shadow: none !important;
        margin-bottom: 2px !important;
    }
    .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled) .p-accordion-header-link {
        background-color: #CCB778 !important;
        color: white;
        outline: none;
    }
    .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
    .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
        background-color: #CBA123 !important;
        color: white !important;
    }

    /* -------- iFrame --------- */
    .resp-container {
        position: relative;
        overflow: hidden;
        padding-top: 56.25%;
    }
    .resp-iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
    .bg-vgold {
        background: linear-gradient(90deg, rgba(200,145,30,1) 34%, rgba(222,189,117,1) 58%, rgba(231,207,156,1) 100%);
    }
    .bg-vgold_mob {
        background: #C8911E;
    }

    /* ----------- Modal/Dialog ---------- */
    .p-dialog .p-dialog-header {
        padding: .5em !important;
        right: 0;
        justify-content: flex-end !important;
    }
    .p-dialog .p-dialog-content {
        padding: .5em !important;
    }
    
</style>
   