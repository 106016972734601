<template>
    <nav id="header" class="absolute w-full z-30 top-0 text-white">
        <div class="flex flex-wrap w-full container mx-auto items-center mt-0 py-2 px-5">
            <div class="flex-1 items-center">
                <a class="toggleColour text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl" href="/">
                    <img class="md:w-auto w-3/4 md:mt-2" :src="logo_[$i18n.locale]" alt="Page Logo">
                </a>
            </div>
            
            <div class="block lg:hidden">
                <button aria-label="Open Menu" @click="drawer" class="flex h-12 w-12 items-center text-white hover:text-gray-900 focus:outline-none focus:shadow-outline hover:scale-105 duration-100 ease-in-out">
                    <svg class="fill-current h-12 w-12" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <title>Menu</title>
                        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                    </svg>
                </button>
            </div>
                       
                        
            <aside id="sidebar" class="transform py-2 px-5 top-0 left-0 w-full bg-sgbgold fixed h-full overflow-auto overscroll-none ease-in-out transition-all duration-100 z-30" :class="isOpen ? 'translate-x-0' : '-translate-x-full'">
                <div class="flex">
                    <div @click="isOpen = false" class="w-full">
                        <div class="flex-1">
                            <a class="toggleColour text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl" href="/">
                                <img class="md:w-auto w-3/4" :src="logo_[$i18n.locale]" alt="Page Logo">
                            </a>
                        </div>
                    </div>
                    <div @click="isOpen = false" class="flex-1 text-right text-white">
                            <i class="mdi mdi-close text-6xl"></i>
                    </div>
                    
                </div>
                <div @click="isOpen = true" class="flex-1 lg:text-right text-left items-center relative border-none mx-0 lg:mx-2 my-2 py-1 sm:p-0 border-transparent outline-none focus:outline-none hover:text-white rounded-sm">
                    <div class="lg:mr-10">
                        <language-switcher v-slot="{ links }" active-class="router-link-exact-active">
                            <router-link v-for="link in links" :key="link.langIndex" :to="link.url" :class="link.activeClass" exact-active-class="">
                                <span class="md:text-xl font-semibold cursor-pointer">{{ link.langName }}</span>
                            </router-link>
                        </language-switcher>
                    </div>
                </div>
                <span @click="isOpen = false" class="flex items-center text-left hover:bg-indigo-500 hover:text-white">
                    <div class="inline-block mt-5">
                        <ul class="list-none lg:flex lg:text-2xl text-base">
                            <li class="text-4xl"><a href="#jubiläum">{{ $t('header.link-1') }}</a></li>
                            <li class="text-4xl py-7"><a href="#geschichte">{{ $t('header.link-2') }}</a></li>
                            <li class="text-4xl pb-7"><a href="#portraits">{{ $t('header.link-3') }}</a></li>
                            <li class="text-4xl"><a href="#videos">{{ $t('header.link-4') }}</a></li>
                        </ul>
                    </div>
                </span>
                
            </aside>
        
                    
            <div class="w-full hidden lg:flex lg:items-center lg:w-auto">
                <div class="flex-1 lg:flex-grow lg:text-right text-left items-center relative border-none mx-0 lg:mx-2 my-2 py-1 sm:p-0 border-transparent outline-none focus:outline-none hover:text-white rounded-sm">
                    <div class="lg:mr-10">
                        <language-switcher v-slot="{ links }" active-class="router-link-exact-active">
                            <router-link v-for="link in links" :key="link.langIndex" :to="link.url" :class="link.activeClass" exact-active-class="">
                                <span class="md:text-xl font-semibold cursor-pointer">{{ link.langName }}</span>
                            </router-link>
                        </language-switcher>
                    </div>
                </div>
                <div id="navi" class="inline-block">
                    <ul class="list-none lg:flex lg:text-2xl text-base">
                        <li><a href="#jubiläum">{{ $t('header.link-1') }}</a></li>
                        <li class="mx-10"><a href="#geschichte">{{ $t('header.link-2') }}</a></li>
                        <li class="mr-10"><a href="#portraits">{{ $t('header.link-3') }}</a></li>
                        <li><a href="#videos">{{ $t('header.link-4') }}</a></li>
                    </ul>
                </div>
            </div>
            
        </div>
        <div class="container mx-auto px-5 text-right">
                
                <button v-on:click="btnClick_de" v-if="$i18n.locale === 'de'" class="bg-transparent hover:bg-white text-white md:text-xl text-base hover:text-sgbblue py-1 md:py-2 leading-6 px-10 border border-white hover:border-transparent rounded-full">{{ $t("header.btn") }}</button>
                <button v-on:click="btnClick_fr" v-if="$i18n.locale === 'fr'" class="bg-transparent hover:bg-white text-white md:text-xl text-base hover:text-sgbblue py-1 md:py-2 leading-6 px-10 border border-white hover:border-transparent rounded-full">{{ $t("header.btn") }}</button>
                <button v-on:click="btnClick_it" v-if="$i18n.locale === 'it'" class="bg-transparent hover:bg-white text-white md:text-xl text-base hover:text-sgbblue py-1 md:py-2 leading-6 px-10 border border-white hover:border-transparent rounded-full">{{ $t("header.btn") }}</button>
                
        </div>
        
    </nav>
    
</template>

<script>
    import logo_de from '@/assets/img/ftr_logo_de.png'
    import logo_fr from '@/assets/img/ftr_logo_fr.png'
    import logo_it from '@/assets/img/ftr_logo_it.png'

    export default {
        
        name: "Navigation",
        data() {
            
            return {
                isOpen: false, 
                open: false,
                logo_: {
                    de: logo_de,
                    fr: logo_fr,
                    it: logo_it
                }   
            }
            
            
        },
       
        
        methods: {
            drawer() {
                this.isOpen = !this.isOpen;
            },

            btnClick_de: function() {
                window.open("https://www.sgb-fss.ch/spenden/jetzt-spenden/")
            },
            btnClick_fr: function() {
                window.open("https://www.sgb-fss.ch/fr/soutien/dons/")
            },
            btnClick_it: function() {
                window.open("https://www.sgb-fss.ch/it/donazioni/dona-ora/")
            },
            
            toggle() {
                this.open = !this.open
            },

            
            
            
        }
    }
</script>

<style>
    

    a.router-link-exact-active {
        color: #E65834;
    }
    .router-language-switcher a {
        margin: 0;
    }
    
    .router-language-switcher a::after {
        content: 'l';
        color: white;
        margin: 0 0.75rem;
        font-size: 1.25rem;
    }
    
    .router-language-switcher a:last-of-type::after {
        content: '' !important;
    }
    #navi li:active, #navi li:hover, #navi li:focus {
        text-decoration: underline;
    }


    li>ul                 { transform: translatex(100%) scale(0) }
    li:hover>ul           { transform: translatex(101%) scale(1) }
    li > button svg       { transform: rotate(-90deg) }
    li:hover > button svg { transform: rotate(-270deg) }

    /* Below styles fake what can be achieved with the tailwind config
        you need to add the group-hover variant to scale and define your custom
        min width style.
        See https://codesandbox.io/s/tailwindcss-multilevel-dropdown-y91j7?file=/index.html
        for implementation with config file
    */
    .group:hover .group-hover\:scale-100 { transform: scale(1) }
    .group:hover .group-hover\:-rotate-180 { transform: rotate(180deg) }
    .scale-0 { transform: scale(0) }
    .min-w-32 { min-width: 8rem }
</style>
