import { createWebHistory } from 'vue-router';
import { createLangRouter } from 'vue-lang-router';

import translations from '../lang/translations';
import localizedURLs from '../lang/localized-urls';


import Home from "../views/Home.vue";


const routes = [
 
    {
      path: '/',
      name: 'home',
      component: Home,
    }

];


const langRouterOptions = {
	defaultLanguage: 'de',
	translations,
  localizedURLs,
};
const routerOptions = {
	routes,
	history: createWebHistory(process.env.BASE_URL),
};

const router = createLangRouter(langRouterOptions, routerOptions);

export default router;