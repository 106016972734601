export default {
    de: {
      name: 'DE',
      load: () => { return import('./de.json'); },
    },
    fr: {
      name: 'FR',
      load: () => { return import('./fr.json'); },
    },
    it: {
      name: 'IT',
      load: () => { return import('./it.json'); },
    },
};
